.codeinput {
  padding: 1rem;
  background: rgb(37,38,41);
  background: linear-gradient(90deg, rgba(37,38,41,0.5) 100%, rgba(185,185,185,0) 100%);
  border: 1px solid #F9F9F9;
  border-radius: 2%;
  font-size: 1.5rem;
  text-align: center;
}

.codeinput::placeholder {
  color: #F9F9F9;
  opacity: 1;
}

.codeinput:hover,
.codeinput:focus {
  color: #E7E70F;
}
