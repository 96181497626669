.video-player {
  position: relative;
  padding: 28%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
