.code-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100vh;
  transform: translate(-50%, -50%);
}

.code-view_title {
  margin-top: 10px;
  margin-right: -17px;
  letter-spacing: 17px;
  font-size: 3rem;
}

.code-view_logo {
  font-size: 5rem;
}

.code-view_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.code-view_body {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

@media (min-width: 767px) {
  .code-view {
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    height: auto;
    max-width: 800px;
    background-color: #171818;
    opacity: 0.95;
  }

  .code-view_header {
    border-right: 1px #F9F9F9 solid;
    padding: 2rem;
  }

  .code-view_body {
    width: 35vw;
  }
}
