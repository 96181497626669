.button {
  padding: 10px;
  min-width: 100px;
  color: #F9F9F9;
  background: linear-gradient(90deg, rgba(37,38,41,0.5) 100%, rgba(185,185,185,0) 100%);
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button:hover {
  color: #E7E70F;
  border-color: #F9F9F9;
}

.button:disabled,
.button[disabled] {
  color: #B6B6B6;
}

@media (max-width: 1127px) {
  .button {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .button {
    font-size: 12px;
  }
}

@media (max-width: 447px) {
  .button {
    font-size: 11px;
  }
}
